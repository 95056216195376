import React from 'react'
import Connect from './Connect'

const Hire = () => {
    return (
        <>
            <div>
                <Connect />
            </div>
        </>
    )
}

export default Hire