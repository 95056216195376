import React from 'react'
import ServiceImg from './ServiceImg'

const ServiceMain = () => {
    return (
        <div>
            <div><ServiceImg /></div>
        </div>
    )
}

export default ServiceMain