import React from 'react'
import ServiceOneImg from './ServiceOneImg'

const ServiceOneMain = () => {
    return (
        <div>
            <div>
                <ServiceOneImg />
            </div>
        </div>
    )
}

export default ServiceOneMain